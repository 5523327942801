<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <h1 class="text-light mb-3">Thank you</h1>
        <div class="card shadow-sm p-3 text-center">
            <h4 class="mb-4 mt-5">Your booking has been created</h4>
            <router-link to="/" class="btn text-bg-dark mb-5"
                >Go back</router-link
            >
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
</script>
